<template>
  <div id="dispensation" class="dispensation-container">
    <div v-if="listView">
      <div  class="pull-right">
          <Button id="addDispensationBtn" @click="openAddDispensationForm" label="add_dispensation"> </Button>
      </div>
    </div>
    <div v-if="!listView">
      <div class="pull-left">
        <Button id="cancelBtn" @click="back" label="back" type="outlined"> </Button>
      </div>
    </div>
    <div v-if="!listView && !returnDispensation && addingDispensation && copyDispensationAllowed" class="pull-right">
          <Button id="copyFromPreviousBtn" :disabled="loadingLocally || isLoading" @click="copyLastDispensation" label="copy_from_previous_dispensation" > </Button>
    </div >
    <Form v-if ="listView" key="DispensationList" :name="'DispensationList'" :isEditing ="!listView" :loadFormData="listView"  :getFormUrlParams="'idName=PATIENT&id=' + patientId" :saveFormUrlParams="'?patientId=' + patientId"/>
    <Form v-else-if="returnDispensation" id="ReturnDispensation" key="ReturnDispensation" :name="'ReturnDispensation'" :isEditing="returnDispensation" :loadFormData="returnDispensation" :getFormUrlParams="'idName=DISPENSATION&id=' + dispensationId" :saveFormUrlParams="''"/>
    <Form v-else-if="!addingDispensation" id="dispensationDetails" key="ViewDispensation" :name="'ViewDispensation'" :isEditing="addingDispensation" :loadFormData="addingDispensation" :getFormUrlParams="'idName=PATIENT&id=' + patientId" :saveFormUrlParams="'?patientId=' + patientId"/>
    <Form v-else key="AddDispensation" :name="'AddDispensation'" :isEditing ="addingDispensation" :loadFormData="addingDispensation" :getFormUrlParams="'idName=PATIENT&id=' + patientId" :saveFormUrlParams="'?episodeId=' + patientId"/>
  </div>
</template>

<script>
import Form from '../../../../shared/components/Form.vue'
import Button from '../../../../shared/components/Button.vue'
import EventBus from '@/app/shared/event-bus.js'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'App',
  data: self => ({
    listView: true,
    addingDispensation: false,
    loadingLocally: false,
    returnDispensation: false,
    copyDispensationAllowed: false,
    dispensationId: null
  }),
  created () {
    EventBus.$on('TABLE_ROW_OPENED', this.openForm)
    EventBus.$on('TABLE_ROW_DELETED', this.deleteItem)
    EventBus.$on('TABLE_ROW_RETURN', this.returnItem)
    EventBus.$on('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  destroyed () {
    EventBus.$off('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  async mounted () {
    await this.initializeConfig()
  },
  components: {
    Form,
    Button
  },
  computed: {
    ...mapState('Form', ['isLoading', 'existingData', 'existingVModel']),
    ...mapState('Patient', ['patientId'])
  },
  methods: {
    async initializeConfig () {
      const response = await this.getDispensationConfigs()
      this.copyDispensationAllowed = response.CopyPreviousDispensationEnabled
    },
    openAddDispensationForm () {
      if (this.isLoading) {
        return
      }
      this.addingDispensation = true
      this.listView = false
      this.returnDispensation = false
    },
    async returnItem (data) {
      if (data.listName === 'DispensationDetailsList') {
        this.openReturnDispensationForm(data)
      }
    },
    openReturnDispensationForm (data) {
      this.dispensationId = data.rowData.DispensationId
      if (this.isLoading) {
        return
      }
      this.addingDispensation = false
      this.listView = false
      this.returnDispensation = true
    },
    back () {
      if (this.isLoading) {
        return
      }
      this.addingDispensation = false
      this.listView = true
      this.returnDispensation = false
    },
    openForm (listName) {
      if (this.isLoading) {
        return
      }
      if (listName === 'DispensationDetailsList') {
        this.addingDispensation = false
        this.listView = false
        this.returnDispensation = false
      }
    },
    async copyLastDispensation () {
      this.loadingLocally = true
      var data = {}
      for (const k in this.existingVModel) {
        data = { ...data, ...this.existingVModel[k] }
      }
      const requestData = { patientId: this.patientId, data: data }
      const response = await this.patientPreviousData(requestData)
      if (response.Success) {
        this.updateExistingData(response.Data)
      }
      this.loadingLocally = false
    },
    async deleteItem (data) {
      if (this.isLoading) {
        return
      }
      if (data.listName === 'DispensationDetailsList') {
        const response = await this.removeDispensation(data.rowData.DispensationId)
        if (response.Success) {
          EventBus.$emit('DELETED_ROW_AT', { listName: data.listName, index: data.index })
        }
      }
    },
    formSavedSuccessfully (response) {
      this.back()
    },
    ...mapActions('Form', ['updateExistingData']),
    ...mapActions('Dispensation', ['removeDispensation', 'patientPreviousData', 'getDispensationConfigs'])
  }
}
</script>
<style lang="scss" scoped>
.pull-right {
  justify-content: flex-end;
  display: flex;
  margin: 16px;
}
.pull-left {
  justify-content: flex-start;
  display: flex;
  margin: 16px;
}
.text-no-margin {
  margin: auto;
}
.title-container {
  height: 100%;
  padding: 16px;
  background: $gray;
  border: 1px solid $light-gray;
  box-sizing: border-box;
  border-radius: 12px 12px 0px 0px;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.005em;
}
.dispensation-container {
  height: 100%;
  width: 100%;
  background-color: var(--background-color-primary-light-dark);
  box-sizing: border-box;
  transition: 0.3s;
  display:table;
}
</style>
